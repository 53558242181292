import image1 from '../assets/images/hasan5gvip.png';
import image2 from '../assets/images/hasan5gvipvpn.png';
import image3 from '../assets/images/hs5gbrand.png';
import image4 from '../assets/images/hasan5gking.png';




const productData = [
    {
      id: 1,
      name: "HASAN 5G VIP",
      image: image1,
      productType: "openBackHeadphones",
      description: ["PlayStore"],
      rating: 5,
      timeLeft: 27,
      reseller: "https://hasan5gvip.xyz",
      appLink: "https://play.google.com/store/apps/details?id=com.devmaster.hasanvip.net"
    },
    
    {
      id: 2,
      name: "HASAN 5G VIP VPN",
      image: image2,
      productType: "openBackHeadphones",
      description: ["OVPN", "UDP", "V2RAY", "SSH", "SLOWDNS"],
      rating: 5,
      timeLeft: 27,
      reseller: "https://hasan5gvip.xyz",
      appLink: "https://tinyurl.com/Hasan5GvipVPN-Apk"
    },
    {
      id: 3,
      name: "HS 5G BRAND",
      image: image3,
      productType: "openBackHeadphones",
      description: ["OVPN", "UDP", "V2RAY"],
      rating: 5,
      timeLeft: 24,
      reseller: "https://hasan5gvip.xyz",
      appLink: "https://tinyurl.com/Hs5gBrand-Apk"
    }, {
      id: 4,
      name: "HASAN 5G VIP",
      image: image1,
      productType: "openBackHeadphones",
      description: ["OVPN", "UDP", "V2RAY"],
      rating: 5,
      timeLeft: 27,
      reseller: "https://hasan5gvip.xyz",
      appLink: "https://tinyurl.com/Hasan5Gvip-Apk"
    },
    {
      id: 5,
      name: "HASAN 5G KING",
      image: image4,
      productType: "openBackHeadphones",
      description: ["OVPN", "UDP", "V2RAY"],
      rating: 5,
      timeLeft: 27,
      reseller: "https://hasan5gvip.xyz",
      appLink: "https://tinyurl.com/Hasan5GKing-Apk"
    }
  ];
  export default productData;
